/**
 * 数值配置
 * 配置各种策划提供的数值
 *
 */
export default {
  depositDefaultOptions: [
    { get: 1200, price: 1200 },
    { get: 2000, price: 2000 },
    { get: 10000, price: 9900, hot: 1 },
    { get: 20000, price: 19800, hot: 1 },
    { get: 50000, price: 49500, hot: 1 },
    { get: 100000, price: 99000, hot: 1 },
    { get: 200000, price: 198000, hot: 1 },
    { get: 400000, price: 400000, hot: 1 },
    { get: 1000000, price: 1000000 },
  ],
  firstRechargeAmount: 2000,
  firstRechargeBonusRate: 0.2,
  firstRechargedWithdrawNeedBets: 60000,
  defaultDepositGot: 20,
  defaultDepositPrice: 20,
  minDepositAmount: 1200,
  maxDepositAmount: 2000000,
  minWithdrawAmount: 2000,
  maxWithdrawAmount: 400000,
  maxWithdrawDailyAmount: 6000000,
  inviteWheel: {
    targetAmount: 10000,
  },
  luckyDraw: {
    marqueeAmoundList: [4, 10, 16, 20, 100, 50, 16, 4, 10, 10, 4, 4],
  },
  addHomeScreenRewardFrom: 1,
  addHomeScreenRewardTo: 200,
  gasBurnRatio: 0,
  betGasRatio: 1,
  depositGasRatio: 1,
  useWithdrawLevel: 1,
};
